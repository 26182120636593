"use strict";

(function () {
  'use strict';

  var hashtagsInstagramBusiness = {
    pages: {
      authenticationProblem: 'Esta página apresenta problemas com autenticação de token!'
    },
    hashtags: {
      saving: 'Salvando...',
      save: 'Salvar hashtags',
      activeBoxTitle: 'ativas',
      inactiveBoxTitle: 'utilizadas nos últimos 7 dias',
      invalidHashtagMessage: 'Hashtags em vermelho não retornaram coleta e não serão contabilizadas',
      counterMessage: 'Total de hashtags incluidas',
      placeholder: 'Digite uma hashtag',
      validations: {
        spaces: 'Não pode conter espaços',
        specialChars: 'Não pode conter caracteres especiais',
        alreadyExists: 'Hashtag já cadastrada'
      }
    },
    confirmDialog: {
      confirm: 'Sim, tenho',
      cancel: 'Cancelar',
      text: 'A hashtag só poderá ser excluída ao final do período de 7 dias a partir da sua desativação. Tem certeza que deseja alterar suas hashtags ativas?'
    }
  };
  window.moduleExport({
    hashtagsInstagramBusiness: hashtagsInstagramBusiness
  }, typeof module !== 'undefined' && module);
})();